import { createBrowserRouter } from 'react-router-dom'
import AlterarSenha from '../View/Controller/AlterarSenha'
import Clientes from '../View/Crud/Clientes'
import CondicoesPagamentos from '../View/Crud/CondicoesPagamentos'
import ErroAplicacao from '../Layout/ErroAplicacao'
import ExemploMenu from '../exemplo_apagar/menu'
import Filiais from '../View/Crud/Filiais'
import Grupos from '../View/Crud/Sistema/Grupos'
import Layout from '../Layout/Layout'
import Logout from '../View/Controller/Logout'
import Materiais from '../View/Crud/Materiais/Materiais'
import ParametroLeituraLaboratorio from '../View/Crud/ParametroLeituraLaboratorio/ParametroLeituraLaboratorio'
import Propriedades from '../View/Crud/Propriedades'
import SetFilial from '../View/Controller/SetFilial'
import TesteBox from '../exemplo_apagar/TesteBox'
import Usuarios from '../View/Crud/Sistema/Usuarios'
import Analises from '../View/Crud/Analises'
import TabelaPrecos from '../View/Crud/TabelaPrecos/TabelaPrecos'
import AnalisesGrupos from '../View/Crud/AnalisesGrupos'
import OrdensServicos from '../View/Crud/OrdensServicos/OrdensServicos'
import GerarMapas from '../View/Crud/MapasProducao/GerarMapas'
import TiposMapaProducao from '../View/Crud/MapasProducao/TiposMapaProducao'
import UnidadesDeterminacao from '../View/Crud/Materiais/UnidadesDeterminacao'
import ExibirMapas from '../View/Crud/Leituras/ExibirMapas'
import { APP_CLIENTE } from '../ImportBackend/Config/emDesenvolvimento'
import CooperbomClientes from '../Customizacao/Cooperbom/CooperbomClientes'
import EmitirLaudo from '../View/Crud/Laudos/EmitirLaudo'
import Parametros from '../View/Crud/Parametros'
import ClassificacaoSolo from '../View/Temporario/ClassificacaoSolo'



// TODO - Novo Menu / Modulo 
export const router = createBrowserRouter( [
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErroAplicacao />,
    children: [
      {
        path: "Analises",
        element: <Analises />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "AnalisesGrupos",
        element: <AnalisesGrupos />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "AlterarSenha",
        element: <AlterarSenha />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "Clientes",
        element: APP_CLIENTE === 'COOPERBOM' ? <CooperbomClientes /> : <Clientes />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "CondicoesPagamentos",
        element: <CondicoesPagamentos />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "EmitirLaudo",
        element: <EmitirLaudo />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "Filiais",
        element: <Filiais />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "Grupos",
        element: <Grupos />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "Logout",
        element: <Logout />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "TiposMapaProducao",
        element: <TiposMapaProducao />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "Materiais",
        element: <Materiais />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "GerarMapas",
        element: <GerarMapas />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "ExibirMapas",
        element: <ExibirMapas />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "OrdensServicos",
        element: <OrdensServicos />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "Propriedades",
        element: <Propriedades />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "ParametroLeituraLaboratorio",
        element: <ParametroLeituraLaboratorio />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "Parametros",
        element: <Parametros />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "SetFilial",
        element: <SetFilial />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "TabelaPrecos",
        element: <TabelaPrecos />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "Usuarios",
        element: <Usuarios />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "UnidadesDeterminacao",
        element: <UnidadesDeterminacao />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "/TesteBoxComLogin",
        element: <TesteBox />
      }
    ]
  },
  {
    path: "/ErroAplicacao",
    element: <ErroAplicacao />
  },
  {
    path: "/ExemploMenu",
    element: <ExemploMenu />
  },
  {
    path: "/TesteBox",
    element: <TesteBox />
  },
  {
    path: "/Classificacao",
    element: <ClassificacaoSolo />
  }

  /*
    {
      path: "/",
      element: <LayOut />,
      errorElement: <ErroAplicacao />,
      children: [
        {
          path: "cadastrocliente/:idCliente",
          element: <CadastroCliente />,
          errorElement: <ErroAplicacao />
        },
        {
          path: "cadastrofornecedor",
          element: <CadastroFornecedor />,
          errorElement: <ErroAplicacao />
        }
      ],
    },
    {
      path: "*",
      element: <ErroNavegacao />
    }
    */
] );