export const EMDESENVOLVIMENTO: boolean = false

export const VERSAO_SISTEMA = 'RC01.49'

export const PORT_ENDPOINT: string = process.env.REACT_APP_PORT_ENDPOINT as string

export const APP_CLIENTE: string = process.env.REACT_APP_CLIENTE as string

export const URL_ENDPOINT: string = EMDESENVOLVIMENTO ? 'localhost' : process.env.REACT_APP_URL_ENDPOINT as string

export const URL_CLIENT: string = 'http'.concat( EMDESENVOLVIMENTO ? '://' : 's://' ).concat( URL_ENDPOINT ).concat( EMDESENVOLVIMENTO ? ':8080/' : '/' )

export const ENDPOINT_GRAPHQL: string = 'http'.concat( EMDESENVOLVIMENTO ? '://' : 's://' ).concat( URL_ENDPOINT ).concat( ':' ).concat( PORT_ENDPOINT.toString() ).concat( '/graphql' )

export const ENDPOINT: string = 'http'.concat( EMDESENVOLVIMENTO ? '://' : 's://' ).concat( URL_ENDPOINT ).concat( ':' ).concat( PORT_ENDPOINT.toString() ).concat( '/' )

export const TAMANHO_MAXIMO_SELFIE = 20000 * 1024
