import { Typography } from '@mui/material';
import React, { useEffect } from 'react'

export default function ClassificacaoSoloTriangulo ( { valorArgila = 0, valorSilte = 0, valorAreia = 0 }: { valorArgila: number, valorSilte: number, valorAreia: number } ) {

  const exibirTriangulo = () => {

    var divTriangulo = document.getElementById( 'divTRIANGULO' ) as any;
    var divTudo = document.getElementById( 'divTUDO' ) as any;

    if ( valorAreia + valorSilte + valorArgila === 100 ) {
      divTriangulo.style.display = "none";
      divTudo.style.display = "block";
      atualizarTriangulo()
    } else {
      // console.log('Valor Diferente de 100')
      // divTriangulo.style.display = "block";
      // divTudo.style.display = "none";
    }

  }

  const atualizarTriangulo = () => {

    let existeClassificacao: boolean = false

    var divTriangulo = document.getElementById( 'divTRIANGULO' ) as any;
    var divTudo = document.getElementById( 'divTUDO' ) as any;

    existeClassificacao = existeClassificacao || atualizarElemento( "divARGILA", valorArgila > 40 && valorArgila < 60 && valorSilte < 40 && valorAreia < 45 )
    existeClassificacao = existeClassificacao || atualizarElemento( "divMUITOARGILOSA", valorArgila >= 60 && valorSilte < 40 && valorAreia < 40 )
    existeClassificacao = existeClassificacao || atualizarElemento( "divARGILOSILTOSA", valorArgila >= 40 && valorSilte >= 40 )
    existeClassificacao = existeClassificacao || atualizarElemento( "divARGILOARENOSA", valorArgila > 35 && valorAreia >= 45 )
    existeClassificacao = existeClassificacao || atualizarElemento( "divFRANCOARGILOSA", valorArgila > 27 && valorArgila < 40 && valorAreia > 20 && valorAreia < 45 )
    existeClassificacao = existeClassificacao || atualizarElemento( "divFRANCOARGILOARENOSA", valorArgila >= 20 && valorArgila <= 35 && valorSilte <= 28 && valorAreia >= 45 )
    existeClassificacao = existeClassificacao || atualizarElemento( "divFRANCOARGILOSILTOSA", valorArgila >= 27 && valorArgila <= 40 && valorAreia <= 20 )
    existeClassificacao = existeClassificacao || atualizarElemento( "divFRANCOSILTOSA", valorArgila >= 12 && valorArgila < 27 && valorSilte >= 50 )
    existeClassificacao = existeClassificacao || atualizarElemento( "divFRANCOSILTOSA2", valorArgila <= 12 && valorSilte >= 50 && valorSilte <= 80 )
    existeClassificacao = existeClassificacao || atualizarElemento( "divSILTE", valorArgila < 12 && valorSilte > 80 )
    existeClassificacao = existeClassificacao || atualizarElemento( "divFRANCA", valorArgila >= 7 && valorArgila <= 27 && valorSilte >= 28 && valorSilte < 50 && valorAreia < 52 )
    existeClassificacao = existeClassificacao || atualizarElemento( "divFRANCOARENOSO", valorArgila < 7 && valorSilte < 50 && valorAreia < 52 && valorAreia > 43 )
    existeClassificacao = existeClassificacao || atualizarElemento( "divFRANCOARENOSO2", valorArgila < 20 && valorAreia >= 52 && valorSilte + 2 * valorArgila > 30 )
    existeClassificacao = existeClassificacao || atualizarElemento( "divAREIAFRANCA", valorSilte + 2 * valorArgila <= 30 && valorAreia >= 70 && valorAreia < 85 )
    existeClassificacao = existeClassificacao || atualizarElemento( "divAREIAFRANCA2", valorSilte + 1.5 * valorArgila > 15 && valorAreia >= 85 && valorAreia <= 90 )
    existeClassificacao = existeClassificacao || atualizarElemento( "divAREIA", ( valorSilte + 1.5 * valorArgila ) <= 15 && valorAreia >= 85 )

    console.log( 'existeClassificacao', existeClassificacao )

    if ( existeClassificacao ) {
      divTriangulo.style.display = "none";
      divTudo.style.display = "block";
    } else {
      divTriangulo.style.display = "block";
      divTudo.style.display = "none";
    }

  }

  // Função auxiliar para atualizar a visibilidade de um elemento com base em uma condição
  const atualizarElemento = ( elementId: any, condicao: boolean ): boolean => {
    var elemento = document.getElementById( elementId ) as any;
    if ( condicao ) {
      elemento.style.display = 'block';
    } else {
      elemento.style.display = 'none';
    }

    if ( condicao ) {
      console.log( 'OK Classificação: ', elementId )
    }

    return condicao
  }

  useEffect( () => {
    exibirTriangulo()
  }, [valorAreia, valorArgila, valorSilte] )

  return (
    <>
      <div id="divTRIANGULO">
        <Typography component="h6" variant="h5" align="right">% Total: {valorAreia + valorArgila + valorSilte}%</Typography>
        <Typography component="h6" variant="h5" align="right">NÃO EXISTE CLASSIFICAÇÃO</Typography>
        <img src="/classificacaoTextural/triangulo.png" width="864" height="787" alt="Argila" className="clsTexturalResponsiva" />
      </div>

      <div id="divTUDO">
        <div className="clsTexturalOculta" id="divARGILA">
          <img src="classificacaoTextural/argila.png" width="864" height="787" alt="Argila" className="clsTexturalResponsiva" />
        </div>
        <div className="clsTexturalOculta" id="divMUITOARGILOSA">
          <img src="classificacaoTextural/muito_argilosa.png" width="864" height="787" alt="Argila" className="clsTexturalResponsiva" />
        </div>
        <div className="clsTexturalOculta" id="divARGILOSILTOSA">
          <img src="classificacaoTextural/argilo_siltosa.png" width="864" height="787" alt="Argila" className="clsTexturalResponsiva" />
        </div>
        <div className="clsTexturalOculta" id="divARGILOARENOSA">
          <img src="classificacaoTextural/argilo_arenosa.png" width="864" height="787" alt="Argila" className="clsTexturalResponsiva" />
        </div>
        <div className="clsTexturalOculta" id="divFRANCOARGILOSA">
          <img src="classificacaoTextural/franco_argilosa.png" width="864" height="787" alt="Argila" className="clsTexturalResponsiva" />
        </div>
        <div className="clsTexturalOculta" id="divFRANCOARGILOARENOSA">
          <img src="classificacaoTextural/franco_argilo_arenosa.png" width="864" height="787" alt="Argila" className="clsTexturalResponsiva" />
        </div>
        <div className="clsTexturalOculta" id="divFRANCOARGILOSILTOSA">
          <img src="classificacaoTextural/franco_argilo_siltosa.png" width="864" height="787" alt="Argila" className="clsTexturalResponsiva" />
        </div>
        <div className="clsTexturalOculta" id="divFRANCOSILTOSA">
          <img src="classificacaoTextural/franco_siltosa.png" width="864" height="787" alt="Argila" className="clsTexturalResponsiva" />
        </div>
        <div className="clsTexturalOculta" id="divFRANCOSILTOSA2">
          <img src="classificacaoTextural/franco_siltosa.png" width="864" height="787" alt="Argila" className="clsTexturalResponsiva" />
        </div>
        <div className="clsTexturalOculta" id="divSILTE">
          <img src="classificacaoTextural/silte.png" width="864" height="787" alt="Argila" className="clsTexturalResponsiva" />
        </div>
        <div className="clsTexturalOculta" id="divFRANCA">
          <img src="classificacaoTextural/franca.png" width="864" height="787" alt="Argila" className="clsTexturalResponsiva" />
        </div>

        <div className="clsTexturalOculta" id="divFRANCOARENOSO">
          <img src="classificacaoTextural/franco_arenoso.png" width="864" height="787" alt="Argila" className="clsTexturalResponsiva" />
        </div>
        <div className="clsTexturalOculta" id="divFRANCOARENOSO2">
          <img src="classificacaoTextural/franco_arenoso.png" width="864" height="787" alt="Argila" className="clsTexturalResponsiva" />
        </div>
        <div className="clsTexturalOculta" id="divAREIAFRANCA">
          <img src="classificacaoTextural/areia_franca.png" width="864" height="787" alt="Argila" className="clsTexturalResponsiva" />
        </div>
        <div className="clsTexturalOculta" id="divAREIAFRANCA2">
          <img src="classificacaoTextural/areia_franca.png" width="864" height="787" alt="Argila" className="clsTexturalResponsiva" />
        </div>
        <div className="clsTexturalOculta" id="divAREIA">
          <img src="classificacaoTextural/areia.png" width="864" height="787" alt="Argila" className="clsTexturalResponsiva" />
        </div>
      </div>
    </>
  )

}